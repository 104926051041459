<template>
	<div class="py-12 container mx-auto px-5 md:px-8">
		<div class="mx-8">
			<h1 class="text-xl font-bold text-black md:text-right mb-5">خدماتنا</h1>
			<router-link class="flex justify-center md:justify-start items-center" :to="{ name: 'Services' }">
				<span class="mx-2 text-infoGray md:text-black font-medium tracking-normal"> كل الخدمات</span>
				<img src="../../assets/long-arrow-pointing-to-the-right.svg" alt="خدمات زين" />
			</router-link>
		</div>
		<swiper
			:spaceBetween="50"
			:slidesPerView="slideBrakpoints"
			class="my-8 mx-auto "
			:pagination="{ clickable: true }"
		>
			<swiper-slide v-for="(item, index) in data" :key="index" class="border border-gray-100">
				<div class="h-52 w-full overflow-hidden">
					<img :src="item.img" alt="" class="h-full w-full object-cover" />
				</div>
				<div class="p-5 text-right  leading-relaxed">
					<h3 class="text-info text-lg">{{ item.title }}</h3>
					<p class="text-DarkBlue my-3">{{ item.p }}</p>
					
				</div>
			</swiper-slide>
		</swiper>
	</div>
</template>
<script>
import SwiperCore, { Pagination, Scrollbar } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

import img1 from '../../assets/MaskGrou606.png';
import img2 from '../../assets/img2.png';
import img3 from '../../assets/img3.png';

SwiperCore.use([Pagination, Scrollbar]);

export default {
	name: '',
	computed: {
		slideBrakpoints() {
			if (window.innerWidth > 1024) {
				return 3;
			} else if (window.innerWidth > 768) {
				return 2;
			} else if (window.innerWidth > 320) {
				return 1;
			}
			return 1;
		},
	},
	data() {
		return {
			data: [
				{
					title: 'خدمات التموين بالمواد الغذائية والإعاشة',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: img1,
				},
				{
					title: 'خدمات النظافة',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: img2,
				},
				{
					title: 'تقديم خدمات الغرف وأعمال النظافة والغسيل.',
					p: 'القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية',
					img: img3,
				},
			],
			swiperOption: {
				breakpoints: {
					1024: {
						slidesPerView: 3,
					},
					768: {
						slidesPerView: 2,
					},
					320: {
						slidesPerView: 1,
					},
				},
			},
		};
	},
	components: {
		Swiper,
		SwiperSlide,
	},
};
</script>
<style lang="scss">
.Pattren-bg {
	background: url(../../assets/Pattren.svg);
}
</style>
